import { PlanTypeEnum, UserState, StatusTypeEnum } from '.';

export const initialState: UserState = {
  loading: false,
  editLoading: false,
  pageNo: 1,
  pageSize: 10,
  totalRow: 0,
  search: '',
  list: [],
  form: {
    _id: '',
    name: '',
    password: '',
    email: '',
    totalProject: 1,
    plan: PlanTypeEnum.FREE,
    isActive: StatusTypeEnum.ACTIVE,
    actions: false,
    profilePic: '',
    createdAt: '',
  },
  sortColumn: '',
  sortDir: '',
  add: false,
  buttonLoading: false,
  disableAccount: false,
  deleteAccount: false,
  Balcncelist: []
};
